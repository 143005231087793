@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* La font-size de html sert de base pour le calcul des rem */
/* -------------------------------------------------------- */
html {
  /* Cette valeur correspond à la division de 16px (taille de la font-size de base) par la width de l'écran utilisé
     pour les maquettes (1920px) multiplié par 100 pour obtenir une valeur en % => 16/1920 = 0.00833 * 100 = 0.833 */
  font-size: 0.8333333333333vw;
}

/*html {
  !* Cette valeur correspond à la division de 16px (taille de la font-size de base) par la height de l'écran utilisé
       pour les maquettes (1080px) multiplié par 100 pour obtenir une valeur en % => 16/1080 = 0.01481 * 100 = 1.481 *!
  font-size: 1.481481481vh;
}

@media screen and (max-aspect-ratio: 16/9) and (min-width: 1024px) {
  html {
    !* Cette valeur correspond à la division de 16px (taille de la font-size de base) par la width de l'écran utilisé
       pour les maquettes (1920px) multiplié par 100 pour obtenir une valeur en % => 16/1920 = 0.00833 * 100 = 0.833 *!
    font-size: 0.8333333333333vw;
  }
}*/

/* ------------------------------------------------------- */
/* VARIABLES COULEURS */
:root {
  --textGrey: #909090;
  --textGreyInfos: #a5a5a5;
  --textGreyOpacity20: rgba(144, 144, 144, 0.2);
  --textGreyOpacity10: rgba(144, 144, 144, 0.1);
  --textBlack: #121212;
  --textPrimary: #1730bf;
  --blueOpx: #1730bf;
  --blueOpxOpacity10: #1730bf1a;
  --blueOpacity: #e0e3f6;
  --lightBlue: #46bde2;
  --iconBlue: #4f95ff;
  --blueSecondary: #4f95ff;
  --iconBlueOpacity: #4f95ff10;
  --darkBlue: #0d164f;
  --red: #e43e3e;
  --lightRed: #f8d7da;
  --redOpacity: #e43e3e1a;
  --green: #50ba87;
  --darkGreen: #285d43;
  --lightGreen: #c7e9d8;
  --greenOpacity: #50ba871a;
  --blue: #4f95ff;
  --purple: #916be2;
  --orange: #ff8717;
  --white: #ffffff;
  --borderGrey: #eeeeee;
  --backgroundBody: #f7f7fb;
}

/* ------------------------------------------------------- */

body {
  font-family: 'Inter', sans-serif;
  color: var(--textBlack);
}

a div,
a span,
a p {
  font-weight: normal;
}

/* ------------------------------------------------------- */

/* Personnalisation de la couleur du placeholder INPUT */
input::-webkit-input-placeholder {
  color: var(--textGrey);
  font-size: 0.875rem;
}

input:-moz-placeholder {
  color: var(--textGrey);
  font-size: 0.875rem;
}

input::-moz-placeholder {
  color: var(--textGrey);
  font-size: 0.875rem;
}

input:-ms-input-placeholder {
  color: var(--textGrey);
  font-size: 0.875rem;
}

/* PERSONNALISATION CHECKBOX */
input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

/* PERSONNALISATION RADIO */
.blueOpx > input[type='radio'] {
  accent-color: var(--blueOpx);
}
.red > input[type='radio'] {
  accent-color: var(--red);
}
.green > input[type='radio'] {
  accent-color: var(--green);
}
.blue > input[type='radio'] {
  accent-color: var(--blue);
}
.purple > input[type='radio'] {
  accent-color: var(--purple);
}
.textGrey > input[type='radio'] {
  accent-color: var(--textGrey);
}
.orange > input[type='radio'] {
  accent-color: var(--orange);
}
.white > input[type='radio'] {
  accent-color: var(--white);
}

input[type='radio']:not(:checked) {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50%;
  background: transparent;
}
input[type='radio']:not(:checked) {
  border: 2px solid #eeeeee;
}
input[type='radio'].disabled:not(:checked) {
  background-color: #eeeeee;
  border: none;
}

input[type='radio'].disabled:checked {
  appearance: none !important;
  -webkit-appearance: none !important;
  background-color: white !important;
  border: 2px solid #eeeeee !important;
  border-radius: 50% !important;
  position: relative !important;
}
/* style du point à l'intérieur du radio */
input[type='radio'].disabled:checked::after {
  content: '' !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 0.6rem !important;
  height: 0.6rem !important;
  background-color: #eeeeee !important;
  border-radius: 50% !important;
}

.auth-layout-img {
  object-fit: cover;
  background-image: url('../assets/images/auth_bg_logo.svg');
}

/* PERSONNALISATION TOGGLE */
.custom-toggle {
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 1rem;
  border-radius: 8px;
  background-color: var(--white);
  transition: background-color 0.2s ease;
}

.custom-toggle input[type='checkbox'] {
  display: none;
}

.custom-toggle .slider {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  top: 0.125rem;
  left: 0.125rem;
  border-radius: 50%;
  background-color: var(--white);
  transition: transform 0.2s ease;
}

.custom-toggle input[type='checkbox']:checked + .slider {
  transform: translateX(0.9375rem);
}

/* SKELETON LOADER ANIMATION */
.skeleton-loader-anim,
.skeleton-loader-anim-darker {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeleton-loader-anim::before,
.skeleton-loader-anim-darker::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 200px;
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

.skeleton-loader-anim::before {
  background: linear-gradient(
    to right,
    transparent 0%,
    #ffffff 50%,
    transparent 100%
  );
}

.skeleton-loader-anim-darker::before {
  background: linear-gradient(
    to right,
    transparent 0%,
    #eeeeee 50%,
    transparent 100%
  );
}

.tooltip a {
  color: var(--blueOpx);
}

.min-w-fit-custom {
  min-width: fit-content;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

/* personnalisation de la scrollbar */
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
}

.scroll-invisible::-webkit-scrollbar {
  width: 0px;
}

/* Corrige le background blanc lors de l\'auto-complétion par google chrome */
input:-webkit-autofill {
  -webkit-background-clip: text;
}

/* map */
#map {
  width: 100% !important;
  height: 100% !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}

#map > div {
  position: absolute !important;
}

a[rel='noopener'] {
  display: none !important;
}

.gm-control-active,
.gmnoprint,
.gm-style-cc {
  display: none !important;
}

/* Card location dans components/information  */
.map-container {
  height: 350px;
  width: 100%;
}

/* Corrige le focus sur les inputs */
input:focus {
  outline: none;
}
.highcharts-credits {
  display: none;
}

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

.highcharts-root {
  width: 100% !important;
  height: 100% !important;
  padding: 1rem;
}

/* permet de masquer l'extraction de texte du document viewer */
.react-pdf__Page__textContent {
  display: none;
}

ul.disc {
  list-style-type: disc; /* or 'circle' or 'square' */
  margin-left: 1.5em; /* Adjust the margin as needed */
}

.users.tabs_wrapper > div > button:not(:last-child) {
  margin-bottom: 0.25rem;
}

.custom-border {
  position: relative;
  width: fit-content;
}

.corner {
  position: absolute;
  width: 2rem;
  height: 2rem;
}

.corner-top-left {
  top: -5px;
  left: -5px;
  border-top: 5px solid black;
  border-left: 5px solid black;
}

.corner-top-right {
  top: -5px;
  right: -5px;
  border-top: 5px solid black;
  border-right: 5px solid black;
}

.corner-bottom-left {
  bottom: -5px;
  left: -5px;
  border-bottom: 5px solid black;
  border-left: 5px solid black;
}

.corner-bottom-right {
  bottom: -5px;
  right: -5px;
  border-bottom: 5px solid black;
  border-right: 5px solid black;
}

.chrome-picker {
  box-shadow: none !important;
  border: 1px solid var(--borderGrey);
  font-family: 'Inter', sans-serif !important;
}

.circle-picker * {
  border-radius: 4px !important;
}

.flexbox-fix svg {
  margin-left: 0.75rem;
}

#dropdown .dropdown-item {
  display: flex;
  align-items: center;
  text-align: start;
  width: 100%;
  padding: 0.5rem 1rem;
  border-left: 1px solid var(--borderGrey);
  border-right: 1px solid var(--borderGrey);
  border-bottom: 1px solid var(--borderGrey);
  transition: background-color 0.2s ease;
}
